import React from 'react';
import queryString from 'query-string';
import {Actions, NavActions} from '../../Contexts/AppContext';

function SelectCvstStoreReturnPage(props) {
  React.useEffect(() => {
    const restoreAddressData = async () => {
      try {
        Actions.setLoading(true);
        const _queryData = queryString.parse(window.location.search);
        const previousPage = window.localStorage.getItem('previousPage') || '';

        if (
          previousPage.match(/\/profile\/?\?active=addr_management/) ||
          previousPage.match(/\/profile\/?\?action=return&active=orders/)
        ) {
          const data = JSON.parse(
            window.localStorage.getItem('stored-addrs-data'),
          );

          Actions.setRuntimeProfileCvsData({
            id: data.id || null,
            storeId: _queryData.CVSStoreID,
            storeName: _queryData.CVSStoreName,
            storeAddress: _queryData.CVSAddress,
            cvsStoreType: _queryData.LogisticsSubType,
            receiverName: data.receiverName,
            receiverPhone: data.receiverPhone,
            isDefault: data.isDefault,
          });

          window.localStorage.removeItem('previousPage');
          window.localStorage.removeItem('stored-addrs-data');
          NavActions.navigate(previousPage);
          return;
        }

        // FIXME: why we need extra check for /checkout?
        // also, setRuntimeProfileCvsData interface is not align, and should not contain any data else than cvs
        if (previousPage.indexOf('/checkout') > -1) {
          const data = JSON.parse(
            window.localStorage.getItem('stored-addrs-data'),
          );

          Actions.setRuntimeProfileCvsData({
            id: data.id || null,
            storeId: _queryData.CVSStoreID,
            storeName: _queryData.CVSStoreName,
            storeAddress: _queryData.CVSAddress,
            cvsStoreType: _queryData.LogisticsSubType,
            receiverName: data.receiverName,
            receiverPhone: data.receiverPhone,
            address: data.address,
            city: data.city,
            town: data.town,
            shippingType: data.shippingType,
            isDefault: data.isDefault,
          });

          if (!!data) {
            try {
              await Actions.setIsUsePoints(data?._cachedIsUsedPoints || false);
              await Actions.setPoints({
                useBirthGiftPoints: data?._cachedPoints.useBirthGiftPoints,
                useRebatePoints: data?._cachedPoints.useRebatePoints,
                isUsePoints: data?._cachedIsUsedPoints || false,
              });
            } catch (e) {
              console.log(e);
              await Actions.setIsUsePoints(false);
              await Actions.setPoints({
                useBirthGiftPoints: 0,
                useRebatePoints: 0,
                isUsePoints: false,
              });
            }
          }

          window.localStorage.removeItem('stored-addrs-data');
        }

        const _localDataStr = window.localStorage.getItem(`checkout-data`);
        let data = {};

        if (_localDataStr) {
          try {
            let _localData = JSON.parse(_localDataStr);
            data = {
              ...data,
              ..._localData,
            };
          } catch (err) {
            //
          }

          window.localStorage.removeItem(`checkout-data`);

          data = {
            ...data,
            storeName: _queryData.CVSStoreName, // mapping to Checkout page state parameters
            storeId: _queryData.CVSStoreID,
            storeAddress: _queryData.CVSAddress,
            cvsStoreType: _queryData.LogisticsSubType,
            merchantTradeNo: _queryData.MerchantTradeNo,
          };
        }
        Actions.setRuntimeCheckoutData(data);
        window.localStorage.removeItem('previousPage');
        NavActions.navigate(previousPage);
      } catch (e) {
        console.log(e);
      } finally {
        Actions.setLoading(false);
      }
    };
    restoreAddressData();
  }, [window.location]);

  return <div>Loading....</div>;
}

export default SelectCvstStoreReturnPage;
